const smoothScroll = () => {
  $(".arrow-loc").click(function() {
    $('html, body').animate({
        scrollTop: $(".arrow-loc").offset().top
    }, 1000);
  });

  $(".arrow-talent").click(function() {
    $('html, body').animate({
        // scrollTop: $(window).scrollTop() + 10
        scrollTop: $(".arrow-talent").offset().top
    }, 1000);
  });

  $(".background-accueil").click(function() {
    $('html, body').animate({
        scrollTop: $("#projects-list").offset().top
    }, 1000);
  });

  $(".carousel-inner").click(function() {
    $('html, body').animate({
        scrollTop: $("#infos-loc").offset().top
    }, 1000);
  });

}

export { smoothScroll };
