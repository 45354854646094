const disapearOnScroll = () => {
  window.onscroll = () => {
    document.querySelectorAll(".talent-name").forEach((element) => {
      var rect = element.getBoundingClientRect();
      if(rect.top <= 160) {
        console.log(rect.top)
        element.style.opacity = 0
      }
      else {
        element.style.opacity = 1
      }
    })
  }
}

const disapearOnScrollAbout = () => {
  window.onscroll = () => {
    document.querySelectorAll(".relou").forEach((element) => {
      var rect = element.getBoundingClientRect();
      if(rect.top <= 160) {
        element.style.opacity = 0
      }
      else {
        element.style.opacity = 1
      }
    })
  }
}

export { disapearOnScroll, disapearOnScrollAbout }
