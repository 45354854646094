const backgroundImageTalents = () => {
  // if (document.querySelector(".list-talents")) {
  //   const mainTalents = document.querySelector(".list-talents")
  //   mainTalents.querySelectorAll(".talent-name").forEach((talent) => {
  //     talent.addEventListener("mouseover", () => {
  //       talent.style.opacity = 0.6;
  //     })
  //     talent.addEventListener("mouseout", () => {
  //       talent.style.opacity = 1;
  //     })
  //   })
  // }
}

export { backgroundImageTalents }
