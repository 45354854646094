const navbarScroll = () => {
  const nav = document.querySelector('.navbar_without_mosaic');
  const line = document.querySelector('.line-navbar');
  const logo = document.querySelector('.logo-sucrerie');
  const logo_jouuue = document.querySelector('.jouuue-logo');

  window.onscroll = () => {
    if(window.scrollY >= 550) {
      nav.style.backgroundColor = "white"
      nav.querySelectorAll('.nav-links').forEach((element) => {
        element.style.color = "black";
      })
      line.style.opacity = 1;
      line.style.color = "black";
      $('.logo-sucrerie').attr('src', "/Logo-sucrerie-horizontal-noir.png");
      $('.jouuue-logo').attr('src', "/Logo-LW-noir.png");
      if (document.querySelector('.overlay')) {
        document.querySelector('.overlay').style.backgroundColor = 'white'
        $('#opening-menu').attr('src', '/BURGER-MENU-NOIR.png')
      }
      document.querySelector('.illustration-mobile').src = '/Logo-illustration-noir.png'
    }
    else {
      nav.style.backgroundColor = "transparent"
      nav.querySelectorAll('.nav-links').forEach((element) => {
        element.style.color = "white";
      })
      line.style.color = "white";
      line.style.opacity = 0.3;
      $('.logo-sucrerie').attr('src', "/Logo-sucrerie-horizontal-blanc.png");
      $('.jouuue-logo').attr('src', "/Logo-LW-blanc.png");
      if (document.querySelector('.overlay')) {
        document.querySelector('.overlay').style.backgroundColor = 'black'
        $('#opening-menu').attr('src', '/MENU-MOBILE_BLANC.png')
      }
      document.querySelector('.illustration-mobile').src = '/Logo-illustration.png'
    }
  }
}

const navbarScrollMosaic = () => {
  const navbar = document.querySelector('.navbar')
  const underNav = document.querySelector('.under-nav-tv-mosaic')

  if(document.querySelector("#fullmosaic").style.display = "grid") {
    window.onscroll = () => {
      if(window.scrollY >= 50) {
        underNav.style.backgroundColor = 'black'
        navbar.style.backgroundColor = 'black'
      }
    }
  }
  else {
    underNav.style.backgroundColor = 'transparent'
    navbar.style.backgroundColor = 'transparent'
  }
}

export { navbarScroll, navbarScrollMosaic }
