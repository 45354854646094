let pArr, rowArr;

const animAbout = () => {
  if (document.querySelectorAll(".anim-paragraphe")) {
    pArr = document.querySelectorAll(".anim-paragraphe");
    rowArr = document.querySelectorAll(".anim-title > .anim-rows");
    let counter = 15;

    pArr.forEach((p) => {
      let lettersArr = [...p.innerHTML];
      let i = 0;
      lettersArr.forEach((letter) => {
        i === 0 ? (p.innerHTML = " ") : "";
        p.innerHTML += `<span class=\"letter\">${letter}</span>`;
        i++;
      });
    });

    rowArr.forEach((row) => {
      row.style.top = `${counter}vh`;
      counter += 15;
    });
  }
};

setTimeout(() => {
  startAnim();
}, 1500);

const startAnim = () => {
  const rowArr = document.querySelectorAll(".anim-title > .anim-rows");
  rowArr.forEach((row, i) => {
    if (i === 0) {
      row.querySelector("span:nth-child(3)").classList.add("anim");
    }
    if (i === 1) {
      row.querySelector("span:nth-child(6)").classList.add("anim");
    }
    if (i === 2) {
      row.querySelector("span:nth-child(2)").classList.add("anim");
      row.querySelector("span:nth-child(7)").classList.add("anim");
      row.querySelector("span:nth-child(11)").classList.add("anim");
    }
    // if (i === 3) {
    //   row.querySelector("span:nth-child(2)").classList.add("anim");
    // }
  });

  document.querySelectorAll("span").forEach((span) => {
    !span.classList.contains("anim") ? (span.style.opacity = 0) : "";
    span.addEventListener("transitionend", moveRows, false);
  });
};

const showFamily = (e) => {
  document.querySelector(".anim-family").style.opacity = 1;
  document.querySelector(".anim-family").addEventListener(
    "transitionend",
    (e) => {
      document.querySelector(".anim-logos").style.opacity = 1;
    },
    false
  );
  document.querySelector(".anim-logos").addEventListener(
    "transitionend",
    (e) => {
      document.querySelector(".anim-title").style.opacity = 0;
      document.querySelector(".anim-title").style.height = '150vh';
      document.querySelector(".end-anim").style.opacity = 1;
      // document.querySelector(".end-anim-step-two").style.opacity = 1;
      // document.querySelector(".end-anim-step-three").style.opacity = 1;
    },
    false
  );
};

const moveRandE = (e, i) => {
  document.querySelectorAll(".anim").forEach((span) => {
    if (span.innerText === "e" && i === 2) {
      span.style.position = "absolute";
      span.style.left = "41%";
    }
    if (span.innerText === "r") {
      span.style.position = "absolute";
      span.style.left = "33%";
    }
    span.addEventListener("transitionend", showFamily, false);
    //opacity transition --> 1 de "family" avec transition 0.5 sec
  });
};

const moveRows = () => {
  rowArr.forEach((row, i) => {
    // setTimeout(() => {
    i === 0 ? (row.style.left = "-35%") : "";
    i === 1 ? (row.style.left = "-31%") : "";
    i === 2 ? (row.style.left = "5%") : "";
    // i === 3 ? (row.style.left = "-32%") : "";
    // }, 3000);
    row.style.top = "35vh";
    row.addEventListener(
      "transitionend",
      (e) => {
        moveRandE(e, i);
      },
      false
    );
  });
};

const moveRowsMobile = () => {
  rowArr.forEach((row, i) => {
    // setTimeout(() => {
    i === 0 ? (row.style.left = "-30%") : "";
    i === 1 ? (row.style.left = "-16%") : "";
    i === 2 ? (row.style.left = "22%") : "";
    i === 3 ? (row.style.left = "-32%") : "";
    // }, 3000);
    row.style.top = "35vh";
    row.addEventListener(
      "transitionend",
      (e) => {
        document.querySelectorAll(".anim").forEach((span) => {
          if (span.innerText === "r") {
            span.style.position = "absolute";
            span.style.left = "26%";
          }
        });
      },
      false
    );
  });
};
//Taille de la font + pourcentage row

export { animAbout };
