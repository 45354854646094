const talentOver = () => {
  if (document.querySelector(".list-talents")) {
    const realisateur = document.querySelector("#sous-menu4")
    const photographe = document.querySelector("#sous-menu5")
    const da = document.querySelector("#sous-menu6")
    const talents = document.querySelectorAll(".talent-name")

    realisateur.addEventListener("mouseover", () => {
      talents.forEach((talent) => {
        talent.style.opacity = "0.5"
        if (talent.dataset.realisateur == "true") {
          talent.style.opacity = "1";
        }
      })
    })
    realisateur.addEventListener("mouseout", () => {
      talents.forEach((talent) => {
        talent.style.opacity = "0.5"
      })
    })

    photographe.addEventListener("mouseover", () => {
      talents.forEach((talent) => {
        talent.style.opacity = "0.5"
        if (talent.dataset.photographe == "true") {
          talent.style.opacity = "1";
        }
      })
    })
    photographe.addEventListener("mouseout", () => {
      talents.forEach((talent) => {
        talent.style.opacity = "0.5"
      })
    })

    da.addEventListener("mouseover", () => {
      talents.forEach((talent) => {
        talent.style.opacity = "0.5"
        if (talent.dataset.da == "true") {
          talent.style.opacity = "1";
        }
      })
    })
    da.addEventListener("mouseout", () => {
      talents.forEach((talent) => {
        talent.style.opacity = "0.5"
      })
    })

  }
}

export { talentOver }
