import { playOnHover } from "../components/play_on_hover"

const mosaicVideoOld = () => {
  if (document.querySelector(".fulltout")) {
    const mosaic = document.querySelector("#mosaic")
    const list = document.querySelector("#list")
    const divList = document.querySelector("#pagepiling")
    const divMosaic = document.querySelector("#fullmosaic")
    const navbar = document.querySelector('.navbar')
    const underMenuMosaic = document.querySelector('.under-nav-tv-mosaic')
    const underMenuList = document.querySelector('.under-nav-tv')
    const sousMenu1 = document.querySelector('#sous-menu1')
    const sousMenu2 = document.querySelector('#sous-menu2')
    const sousMenu3 = document.querySelector('#sous-menu3')

    mosaic.addEventListener("click", () => {
      mosaic.style.opacity = 0.5;
      list.style.opacity = 1;
      list.classList.remove("active");
      mosaic.classList.add("active");
      divMosaic.style.display = "grid"
      divList.style.display = "none"
      navbar.style.backgroundColor = "black"
      underMenuList.style.display = "none"
      underMenuMosaic.style.display = "flex"

      if (document.querySelector('.under-nav-tv-mosaic')) {
        document.querySelector('.under-nav-tv-mosaic').style.backgroundColor = "black"
      }
      selectionVideoMosaic()
      playOnHover()
    })

    list.addEventListener("click", () => {
      mosaic.style.opacity = 1;
      list.style.opacity = 0.5;
      list.classList.add("active");
      mosaic.classList.remove("active");
      divMosaic.style.display = "none"
      divList.style.display = "block"
      underMenuList.style.display = "flex"
      underMenuMosaic.style.display = "none"
      navbar.style.backgroundColor = "transparent"
      if (document.querySelector('.under-nav-tv')) {
        document.querySelector('.under-nav-tv').style.backgroundColor = "transparent"
      }
      playOnHover()
    })
  }
}

const mosaicVideo = () => {
  if (document.querySelector(".fulltout")) {
    const mosaic = document.querySelector("#mosaic")
    const list = document.querySelector("#list")
    const divList = document.querySelector("#pagepiling")
    const divMosaic = document.querySelector("#fullmosaic")
    const navbar = document.querySelector('.navbar')
    const underMenuMosaic = document.querySelector('.under-nav-tv-mosaic')
    const underMenuList = document.querySelector('.under-nav-tv')
    const sousMenu1 = document.querySelector('#sous-menu1')
    const sousMenu2 = document.querySelector('#sous-menu2')
    const sousMenu3 = document.querySelector('#sous-menu3')

    mosaic.addEventListener("click", () => {
      console.log('ici')
      mosaic.style.opacity = 1;
      list.style.opacity = 0.5;
      list.classList.remove("active");
      mosaic.classList.add("active");
      divMosaic.style.display = "grid"
      divList.style.display = "none"
      navbar.style.backgroundColor = "black"
      underMenuList.style.display = "none"
      underMenuMosaic.style.display = "flex"

      if (document.querySelector('.under-nav-tv-mosaic')) {
        document.querySelector('.under-nav-tv-mosaic').style.backgroundColor = "black"
      }
      selectionVideoMosaic()
      playOnHover()
    })

    list.addEventListener("click", () => {
      console.log('ici')
      mosaic.style.opacity = 0.5;
      list.style.opacity = 1;
      list.classList.add("active");
      mosaic.classList.remove("active");
      divMosaic.style.display = "none"
      // divList.style.display = "block"
      underMenuList.style.display = "flex"
      underMenuMosaic.style.display = "none"
      navbar.style.backgroundColor = "transparent"
      if (document.querySelector('.under-nav-tv')) {
        document.querySelector('.under-nav-tv').style.backgroundColor = "transparent"
      }
      playOnHover()
    })
  }
}

const selectionVideoMosaic = () => {
  const music = document.querySelector('#sous-menu1-mosaic')
  const content = document.querySelector('#sous-menu2-mosaic')
  const fiction = document.querySelector('#sous-menu3-mosaic')
  console.log('mosaicVideoici')

  music.addEventListener("click", () => {
    const videosMosaic = document.querySelectorAll('.video-container-mosaic')
    videosMosaic.forEach((videoMosaic) => {
      videoMosaic.style.display = "block"
    })
    music.style.opacity = 1
    content.style.opacity = 0.5
    fiction.style.opacity = 0.5
    videosMosaic.forEach((videoMosaic) => {
      if (!(videoMosaic.dataset.music == "true")) {
        videoMosaic.style.display = "none"
      }
    })
  })

  content.addEventListener("click", () => {
    const videosMosaic = document.querySelectorAll('.video-container-mosaic')
    videosMosaic.forEach((videoMosaic) => {
      videoMosaic.style.display = "block"
    })
    music.style.opacity = 0.5
    content.style.opacity = 1
    fiction.style.opacity = 0.5
    videosMosaic.forEach((videoMosaic) => {
      if (!(videoMosaic.dataset.content == "true")) {
        videoMosaic.style.display = "none"
      }
    })
  })

  fiction.addEventListener("click", () => {
    const videosMosaic = document.querySelectorAll('.video-container-mosaic')
    videosMosaic.forEach((videoMosaic) => {
      videoMosaic.style.display = "block"
    })
    music.style.opacity = 0.5
    content.style.opacity = 0.5
    fiction.style.opacity = 1
    videosMosaic.forEach((videoMosaic) => {
      if (!(videoMosaic.dataset.fiction == "true")) {
        videoMosaic.style.display = "none"
      }
    })
  })


}


const mosaicProjects = () => {
  if (document.querySelector(".main-talent")) {
    const mosaic = document.querySelector("#mosaic")
    const list = document.querySelector("#list")
    const projectList = document.querySelector("#projects-list")
    const projectMosaic = document.querySelector("#fullmosaic")
    const talentNext = document.querySelector("#talent-next-show")
    // const dots = document.querySelector('#pp-nav.right')
    mosaic.addEventListener("click", () => {
      mosaic.style.opacity = 0.7;
      list.style.opacity = 1;
      projectList.style.display = "none"
      projectMosaic.style.display = "grid"
      talentNext.style.display = "none"
      // dots.style.display = "none"
      playOnHover()
    })
    list.addEventListener("click", () => {
      mosaic.style.opacity = 1;
      list.style.opacity = 0.7;
      console.log(list)
      projectList.style.display = "block"
      projectMosaic.style.display = "none"
      talentNext.style.display = "block"
      // dots.style.display = "none"
      playOnHover()
    })

  }
}

export { selectionVideoMosaic }
export { mosaicVideo }
export { mosaicProjects }
