const preloader = () => {
  if (document.querySelector('.preloader')) {
    window.addEventListener('load', () => {
      const preload = document.querySelector('.preloader');
      preload.style.display = "none";
      document.body.style.overflow = '';
      document.body.style.position = '';
    })
  }
}

export { preloader };
