const navOnClick = () => {
  if (document.getElementById('js-menu')) {
    let mainNav = document.getElementById('myNav');
    let navBarToggle = document.getElementById('js-navbar-toggle');
    let open = document.getElementById('opening-menu');
    let close = document.getElementById('closing-menu');

    navBarToggle.addEventListener('click', function () {
      open.style.display = open.style.display === 'none' ? '' : 'none';
      close.style.display = close.style.display === 'none' ? '' : 'none';
      document.getElementById("myNav").style.width = "100%";
    })


    mainNav.addEventListener('click', function () {
      close.style.display = 'none';
      open.style.display = '';
      document.getElementById("myNav").style.width = "0%";
    })
  }
}

export { navOnClick };
