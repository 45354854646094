const playOnHover = () => {
  // if(document.querySelector(".projets-videos")) {
  //   const videos = document.querySelectorAll(".projets-videos")
  //   videos.forEach((element) => {
  //     let video = element.querySelector("#projets-video")
  //     video.addEventListener("mouseover", () => {
  //       video.play()
  //     })
  //     video.addEventListener("mouseout", () => {
  //       video.pause()
  //       // video.currentTime = 0
  //     })
  //   })
  //   const videos2 = document.querySelectorAll("#index-videos-mosaic")
  //   videos2.forEach((element) => {
  //     // console.log(element)
  //     element.addEventListener("mouseover", () => {
  //       element.play()
  //     })
  //     element.addEventListener("mouseout", () => {
  //       element.pause()
  //       // video.currentTime = 0
  //     })
  //   })
  // }
  if (document.querySelector('.fulltout')) {
    const videos3 = document.querySelectorAll("#index-videos")
    // console.log(videos3)
    // videos3.forEach((element) => {
    //   // let video = element.querySelector("#index-videos")
    //   console.log(element)
    //   element.addEventListener("mouseover", () => {
    //     element.play()
    //   })
    //   element.addEventListener("mouseout", () => {
    //     element.pause()
    //   })
    // })
    // ----------------------------------------------------------------
    const entier = document.querySelectorAll(".video-container-mosaic")
    entier.forEach((element) => {
      let videos4 = element.querySelector("#index-videos-mosaic")
      let title_video = element.querySelector(".title-video-mosaic")
      // let video = element.querySelector("#index-videos")
      element.addEventListener("mouseover", () => {
        videos4.play()
        title_video.style.display = "none"
      })
      element.addEventListener("mouseout", () => {
        videos4.pause()
        // videos4.load()
        title_video.style.display = "flex"
      })
    })
  // ----------------------------------------------------------------
  }
  // else if (document.querySelector("#pagepiling")) {
  //   const videos5 = document.querySelectorAll(".video-home")
  //   videos5.forEach((element) => {
  //     console.log(element)
  //     // let video = element.querySelector("#index-videos")
  //     element.addEventListener("mouseover", () => {
  //       element.play()
  //     })
  //     element.addEventListener("mouseout", () => {
  //       element.pause()
  //     })
  //   })
  // }
}

export { playOnHover }
