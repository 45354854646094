const manageColorNavbar = () => {
  //page Talents
  if (document.querySelector('.list-talents')) {

    $(document).ready(function(){
      $(window).scroll(function(){
        // console.log("ici")
        var scroll = $(window).scrollTop();
        // console.log(scroll)
        if (scroll > 300) {
          $(".navbar").css("background" , "black");
        }

        else{
          $(".navbar").css("background" , "transparent");
        }
      })
    })
    // document.querySelector('.navbar').style.backgroundColor = "black"
    // document.querySelector('.under-nav-talent').style.backgroundColor = "black"
  }
}

export { manageColorNavbar };


// const showUnderMenu = () => {
//   if (document.querySelector('.TV-nav')) {
//     const navTV = document.querySelector('.TV-nav');
//     const navTALENT = document.querySelector('.TALENT-nav');
//     const navNoUnderMenu = document.querySelectorAll(".nav-no-undermenu")
//     const underNavTALENT = document.querySelector('.under-nav-talent');
//     const underNavTV = document.querySelector('.under-nav-tv');
//     openUnderMenu(navTALENT, underNavTALENT)
//     openUnderMenu(navTV, underNavTV)
//     closeUnderMenu(navNoUnderMenu, underNavTV, underNavTALENT)
//   }
// }

// const openUnderMenu = (element, underMenu) => {
//   // const nav = document.querySelector('.navbar');
//   element.addEventListener("mouseover", function () {
//     underMenu.style.display = "flex";
//   })
//   // nav.addEventListener("mouseout", function () {
//   //   underMenu.style.display = "";
//   // })
// }

// const closeUnderMenu = (listNav, underNavTV, underNavTALENT) => {
//   listNav.forEach((element) => {
//     element.addEventListener("mouseover", function () {
//       if (underNavTV.style.display == "flex") {
//         underNavTV.style.display = "none";
//       }
//       else if (underNavTALENT.style.display == "flex") {
//         underNavTALENT.style.display = "none"
//       }
//     })

//   })
// }

// export { showUnderMenu };
